<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <v-icon left>mdi-wrench</v-icon>
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Maintenance Requests ({{ totalMaintenances }})</v-toolbar-title
      >

      <v-spacer></v-spacer>
    </v-app-bar>

    <div
      class="mx-4"
      id="maintenance-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <div class="appbar mt-7 mb-3">
        <v-tabs>
          <v-tab
            @change="
              defaultStatus = 'pending'
              activeAction()
            "
          >
            Pending
          </v-tab>
          <v-tab
            @change="
              defaultStatus = 'in_progress'
              activeAction()
            "
          >
            In Progress
          </v-tab>
          <v-tab
            @change="
              defaultStatus = 'finished'
              activeAction()
            "
          >
            Finished
          </v-tab>
          <v-tab
            @change="
              defaultStatus = 'cancelled'
              activeAction()
            "
          >
            Cancelled
          </v-tab>
          <v-tab
            @change="
              defaultStatus = null
              activeAction()
            "
          >
            All
          </v-tab>
        </v-tabs>

        <v-text-field
          v-model="filter.search"
          class="appbar__filter"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search title, building, category and urgency"
          @input="search"
        ></v-text-field>

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-show="false"
              class="ml-10"
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Bulk Actions
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in actions" :key="index">
              <v-list-item-title>{{ item.label }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-data-table
        :headers="headers"
        :items="maintenances"
        v-model="selected"
        :options.sync="options"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [25, 50, 100, -1],
        }"
        @click:row="editItem"
        @update:options="fetch"
        :server-items-length="totalMaintenances"
        :sort-by.sync="defaultFilter"
        :sort-desc.sync="sortOrder"
      >
        <template v-slot:item.status="{ item }">
          <div style="text-align: center">
            <Status :status="item.statusLabel" />
          </div>
        </template>
        <template v-slot:item.building="{ item }">
          <div v-if="item.buildingName">{{ item.buildingName }}</div>
        </template>
        <template v-slot:item.property="{ item }">
          <div v-if="item?.user?.property">
            {{ item?.user?.property?.name }}
          </div>
        </template>
        <template v-slot:item.category="{ item }">
          <div v-if="item.categoryLabel">{{ item.categoryLabel }}</div>
        </template>
        <template v-slot:item.urgency="{ item }">
          <div v-if="item.urgencyLabel">{{ item.urgencyLabel }}</div>
        </template>
        <template v-slot:item.created_at="{ item }">
          <div v-if="item.addedDate">{{ item.addedDate }}</div>
        </template>
        <template v-slot:item.updated_at="{ item }">
          <div v-if="item.updatedDate">{{ item.updatedDate }}</div>
        </template>
      </v-data-table>
      <div
        class="d-flex justify-center mt-3"
        v-if="!(maintenances.length > 0) && !loading"
      >
        <p>No match found.</p>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * ==================================================================================
 * Maintenance Request list
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import { mdiArrowUp, mdiArrowDown } from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'
import Status from '@/components/common/Status'
import FilterTableMixin from '@/utils/mixins/FilterTable'
import MAINTENANCE_STATUS from '@/utils/enums/MaintenanceStatus'

export default {
  name: 'MaintenancesPage',

  mixins: [ControlsMixin, SnackbarMixin, ErrorHandlerMixin, FilterTableMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    Status,
  },

  data() {
    return {
      loading: false,
      filter: {
        search: '',
      },
      icons: {
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
      },
      defaultStatus: 'pending',
      defaultFilter: 'created_at',
      sortOrder: false,
      selected: [],
      headers: [
        {
          text: 'Title',
          value: 'title',
          width: '18%',
        },
        {
          text: 'Property',
          value: 'property',
          width: '10%',
        },
        {
          text: 'Building',
          value: 'building',
          width: '10%',
        },
        {
          text: 'Urgency',
          value: 'urgency',
          width: '10%',
        },
        {
          text: 'Category',
          value: 'category',
          align: 'center',
          width: '15%',
        },

        {
          text: 'Status',
          value: 'status',
          align: 'center',
          width: '10%',
        },
        {
          text: 'Added date',
          value: 'created_at',
          width: '13%',
        },
        {
          text: 'Last Updated',
          value: 'updated_at',
          width: '13%',
        },
      ],
      actions: [
        {
          value: 'delete',
          label: 'Delete',
        },
      ],
      pageTables: 0,
      itemsPerPageTables: 0,
    }
  },

  created() {
    this.clearMaintenances()
    this.fetch()
  },

  computed: {
    ...mapState({
      maintenances: (state) => state.maintenance.list,
      listMeta: (state) => state.maintenance.listMeta,
      totalMaintenances: (state) => state.maintenance.totalMaintenances,
    }),

    statusFilter() {
      return [
        {
          text: 'All',
          value: null,
        },
        ...MAINTENANCE_STATUS,
        {
          text: 'Deleted',
          value: 'deleted',
        },
      ]
    },

    totalItems() {
      return this.listMeta && this.listMeta.total ? this.listMeta.total : 0
    },
  },

  methods: {
    ...mapActions({
      getMaintenances: 'maintenance/getMaintenances',
    }),

    ...mapMutations({
      clearMaintenances: 'maintenance/clearMaintenanceList',
      setLoadingBuilding: 'filter/setLoadingBuilding',
    }),

    filterList(filterValue) {
      this.clearMaintenances()
      this.fetch(null, filterValue)
    },

    activeAction() {
      this.clearMaintenances()
      this.fetch()
    },

    loadMore() {
      if (
        this.listMeta &&
        this.listMeta.current_page < this.listMeta.last_page
      ) {
        this.fetch(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.clearMaintenances()
      this.fetch()
    }, 600),

    async fetch(options, page = 1) {
      this.setLoadingBuilding(true)
      if (this.loading) return

      let params = {
        page: options?.page || 1,
        itemsPerPage: options?.itemsPerPage
          ? options?.itemsPerPage === -1
            ? this.totalMaintenances
            : options.itemsPerPage
          : 25,
        filter: this.defaultStatus,
        sort: options?.sortBy[0]
          ? options.sortDesc[0]
            ? '-' + options.sortBy[0]
            : options.sortBy[0]
          : '',
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.loading = true
      await this.getMaintenances(params)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
          this.setLoadingBuilding(false)
        })
        .finally(() => {
          this.loading = false
          this.setLoadingBuilding(false)
        })
    },
    editItem(item) {
      this.$router.push({
        name: 'maintenance.request.details',
        params: { id: item.id },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.appbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &__btn {
    margin: 0;
  }

  &__filter {
    width: 100%;
    max-width: 400px;
    margin-left: 10px;
  }

  &__status {
    width: 100%;
    max-width: 300px;
  }

  @media (max-width: 540px) {
    flex-direction: column;

    &__btn {
      width: 100%;
      margin-bottom: 15px;
    }

    &__status {
      margin-bottom: 15px;
    }

    &__status,
    &__filter {
      max-width: inherit;
      margin-left: 0;
    }
  }
}
.v-list-item__title {
  cursor: pointer;
}
</style>
