var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-app-bar',{staticClass:"main-appbar",attrs:{"flat":"","color":"transparent"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-wrench")]),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"chip","transition":"fade-transition"}}):_c('v-toolbar-title',{staticClass:"headline font-weight-bold"},[_vm._v("Maintenance Requests ("+_vm._s(_vm.totalMaintenances)+")")]),_c('v-spacer')],1),_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMore),expression:"loadMore"}],staticClass:"mx-4",attrs:{"id":"maintenance-list","infinite-scroll-disabled":_vm.loading,"infinite-scroll-distance":"100"}},[_c('div',{staticClass:"appbar mt-7 mb-3"},[_c('v-tabs',[_c('v-tab',{on:{"change":function($event){_vm.defaultStatus = 'pending'
            _vm.activeAction()}}},[_vm._v(" Pending ")]),_c('v-tab',{on:{"change":function($event){_vm.defaultStatus = 'in_progress'
            _vm.activeAction()}}},[_vm._v(" In Progress ")]),_c('v-tab',{on:{"change":function($event){_vm.defaultStatus = 'finished'
            _vm.activeAction()}}},[_vm._v(" Finished ")]),_c('v-tab',{on:{"change":function($event){_vm.defaultStatus = 'cancelled'
            _vm.activeAction()}}},[_vm._v(" Cancelled ")]),_c('v-tab',{on:{"change":function($event){_vm.defaultStatus = null
            _vm.activeAction()}}},[_vm._v(" All ")])],1),_c('v-text-field',{ref:"searchField",staticClass:"appbar__filter",attrs:{"flat":"","hide-details":"","solo":"","rounded":"","clearable":"","background-color":"grey lighten-3","label":"Search title, building, category and urgency"},on:{"input":_vm.search},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"ml-10",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Bulk Actions ")])]}}])},[_c('v-list',_vm._l((_vm.actions),function(item,index){return _c('v-list-item',{key:index},[_c('v-list-item-title',[_vm._v(_vm._s(item.label))])],1)}),1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.maintenances,"options":_vm.options,"loading":_vm.loading,"footer-props":{
        'items-per-page-options': [25, 50, 100, -1],
      },"server-items-length":_vm.totalMaintenances,"sort-by":_vm.defaultFilter,"sort-desc":_vm.sortOrder},on:{"update:options":[function($event){_vm.options=$event},_vm.fetch],"click:row":_vm.editItem,"update:sortBy":function($event){_vm.defaultFilter=$event},"update:sort-by":function($event){_vm.defaultFilter=$event},"update:sortDesc":function($event){_vm.sortOrder=$event},"update:sort-desc":function($event){_vm.sortOrder=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function({ item }){return [_c('div',{staticStyle:{"text-align":"center"}},[_c('Status',{attrs:{"status":item.statusLabel}})],1)]}},{key:"item.building",fn:function({ item }){return [(item.buildingName)?_c('div',[_vm._v(_vm._s(item.buildingName))]):_vm._e()]}},{key:"item.property",fn:function({ item }){return [(item?.user?.property)?_c('div',[_vm._v(" "+_vm._s(item?.user?.property?.name)+" ")]):_vm._e()]}},{key:"item.category",fn:function({ item }){return [(item.categoryLabel)?_c('div',[_vm._v(_vm._s(item.categoryLabel))]):_vm._e()]}},{key:"item.urgency",fn:function({ item }){return [(item.urgencyLabel)?_c('div',[_vm._v(_vm._s(item.urgencyLabel))]):_vm._e()]}},{key:"item.created_at",fn:function({ item }){return [(item.addedDate)?_c('div',[_vm._v(_vm._s(item.addedDate))]):_vm._e()]}},{key:"item.updated_at",fn:function({ item }){return [(item.updatedDate)?_c('div',[_vm._v(_vm._s(item.updatedDate))]):_vm._e()]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),(!(_vm.maintenances.length > 0) && !_vm.loading)?_c('div',{staticClass:"d-flex justify-center mt-3"},[_c('p',[_vm._v("No match found.")])]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }